@import url('https://fonts.googleapis.com/css?family=inter:regular,bold,italic&subset=latin,latin-ext');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {


    .form-input,
    .form-textarea,
    .form-multiselect,
    .form-select,
    .form-checkbox,
    .react-select-container,
    .form-radio {
        @apply text-sm text-gray-800 bg-white border;
    }

    .form-input,
    .form-textarea,
    .form-multiselect,
    .form-select,
    .react-select-container,
    .form-checkbox {
        @apply rounded;
    }

    .react-select-container .react-select__control {
        @apply border-0 shadow-none
    }

    .react-select-container {
        @apply leading-5  border-gray-200 hover:border-gray-300 focus:border-indigo-300 shadow-sm;
    }

    .react-select-container-error {
        @apply border-red-300 sm:text-sm rounded-md hover:outline-none hover:ring-red-500 hover:border-red-500 sm:text-sm;
    }


    .rel-1 {
        @apply  group col-span-2 block lg:col-span-1;
    }

    .rel-2 {
        @apply  group group block;
    }

    .form-input,
    .form-textarea,
    .form-multiselect,
    .form-select {
        @apply leading-5 py-2 px-3 border-gray-200 hover:border-gray-300 focus:border-indigo-300 shadow-sm;
    }

    .form-input,
    .form-textarea {
        @apply placeholder-gray-400;
    }

    .react-select-container .react-select__control .react-select__value-container .react-select__placeholder {
        @apply text-gray-400;
    }

    .react-select-container-error .react-select__control .react-select__value-container .react-select__placeholder {
        @apply text-red-300;
    }

    .form-search {
        @apply leading-5 py-2 px-3 border-solitude-400 hover:border-gray-200 placeholder-gray-300 text-sm text-blue-1000 bg-white border rounded;
    }

    .dynamic-search-btn {
        @apply border-indigo-800 text-indigo-800 hover:bg-indigo-900 hover:text-white;
    }

    .form-select {
        @apply pr-10;
    }

    .form-checkbox,
    .form-radio {
        @apply text-indigo-500 border border-gray-300;
    }
}




body {
    font-family: 'Inter', sans-serif;
}

.richText p {
    margin-bottom: 1em;
    line-height: 1.6;
    font-size: 16px;
}

/* Stili per titoli di vario livello */
.richText h1, .richText h2, .richText h3, .richText h4, .richText h5, .richText h6 {
    margin-top: 35px;
    font-weight: bold;
}

.richText h1 {
    font-size: 2em;
}

.richText h2 {
    font-size: 1.8em;
}

.richText h3 {
    font-size: 1.6em;
}

.richText a {
    color: #007bff;
    text-decoration: none;
}

.richText a:hover {
    text-decoration: underline;
}

/* Stili per liste */
.richText ul, .richText ol {
    margin-bottom: 0.5em;
    padding-left: 0.5em;
    list-style-type: circle;

}

/* Stili per elementi in grassetto e corsivo */
.richText strong, .richText b {
    font-weight: bold;
}

.richText em, .richText i {
    font-style: italic;
}

.blur {
    filter: blur(4px);
    opacity: 0.6;
}

@media screen and (min-width: 1280px) {
    .curved-section::after {
        content: '';
        position: absolute;
        left: 0;
        width: 100%;
        height: 250px;
        background: inherit;
        clip-path: ellipse(100% 100% at 50% 0%);
        margin-top: -1rem;
    }

    .curved-top {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 250px;
    }

    .curved-top::before {
        content: '';
        position: absolute;
        display: block;
        border-radius: 100% 50%;
        width: 55%;
        background: inherit;
    }

}


.arrow-container {
    width: 25px;
    /* cubic-bezier-easing = sine / mehr Beispiele: https://easings.net/ */
    animation: bounce 1600ms infinite cubic-bezier(0.445, 0.05, 0.55, 0.95);
    cursor: pointer;
    height: 20px;
}

.arrow-down {
    height: 3px;
    background: black;
    transform: rotate(45deg);
    transform-origin: 0% 0%;
    border-radius: 5px;
}
.arrow-down:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 100%;
    border-radius: 5px;
    background: black;
    transform: rotate(-90deg);
    transform-origin: 0% 100%;
}

@keyframes bounce {
    50% {
        transform: translateY(-15px);
    }
}
